// import './PostDetail.css';
// import firebase from '../../../firebase';
// import React, { useState, useEffect } from 'react';
// import { getStorage, ref, getDownloadURL } from 'firebase/storage';
// import { checkUserLoggedIn } from '../../../firebase';
// import editIcon from './img/edit.png'; 

// const PostDetail = ({ post: initialPost, closePostDetail, updatePost }) => {
//   const [post, setPost] = useState(initialPost);
//   const [finalImage, setFinalImage] = useState('');
//   const [currentUser, setCurrentUser] = useState(null);
//   const [isOptionsOpen, setIsOptionsOpen] = useState(false);
//   const [isEditing, setIsEditing] = useState(false);
//   const [editedTitle, setEditedTitle] = useState(initialPost.title);
//   const [editedContent, setEditedContent] = useState(initialPost.content);
//   const [creatorName, setCreatorName] = useState(''); // Add state for creator's name

//   useEffect(() => {
//     // Disable scrolling on the body when the component mounts
//     document.body.classList.add('no-scroll');

//     // Re-enable scrolling on the body when the component unmounts
//     return () => {
//       document.body.classList.remove('no-scroll');
//     };
//   }, []);
  
//   const handleSavePost = (postId) => {
//     const formattedContent = editedContent.replace(/\n/g, '<br/>');
//     console.log('Saving post...');
//     console.log(postId);
//     firebase.firestore().collection('Posts').doc(postId).update({
//       title: editedTitle,
//       content: formattedContent,
//     })
//     .then(() => {
//       setIsEditing(false);
//       const updatedPost = { ...post, title: editedTitle, content: formattedContent };
//       setPost(updatedPost); 
//       updatePost(updatedPost);  // Update the post in the parent component
//     })
//     .catch((error) => {
//       console.error("Error updating post: ", error);
//     });
//   };

//   const handleDeletePost = (postId) => {
//     const confirmDelete = window.confirm('Are you sure you want to delete this post?');
//     if (confirmDelete) {
//       // Delete the post
//       firebase.firestore().collection('Posts').doc(postId).delete()
//         .then(() => {
//           console.log('Post successfully deleted!');
//           // Close the post detail after deleting the post
//           closePostDetail();
//         })
//         .catch((error) => {
//           console.error('Error deleting post: ', error);
//         });
//     }
//   };

//   useEffect(() => {
//     const storage = getStorage();
//     const storageRef = ref(storage, post.imageURL);

//     console.log(post);
//     getDownloadURL(storageRef)
//       .then((url) => setFinalImage(url))
//       .catch((error) => console.log(error));

//     checkUserLoggedIn().then((user) => setCurrentUser(user));

//     firebase.firestore().collection('users').doc(post.author).get()
//     .then((doc) => {
//       if (doc.exists) {
//         setCreatorName(doc.data().displayName);
        
//       }
//     })
//     .catch((error) => console.log(error));
//     console.log(creatorName);
//     console.log(post.author)
//   }, [post.imageURL, post.author]);

//   const handleEditPost = (postId) => {
//     setIsEditing(true);
//     setIsOptionsOpen(false);
//     setEditedContent(post.content.replace(/<br\/>/g, '\n'));
//   };

//   const formatDate = (timestamp) => {
//     if (!timestamp) return '';
//     const year = timestamp.substring(0, 4);
//     const month = timestamp.substring(4, 6);
//     const day = timestamp.substring(6, 8);
//     return `${day}.${month}.${year}`;
//   };

//   return (
//     <div className="post-detail">
//       <div className="post-detail-head">
//       <div className="left-section">
//         {currentUser && currentUser.uid === post.author && !isEditing && (
//           <button className="options-button" onClick={() => setIsOptionsOpen(!isOptionsOpen)}>
//             •••
//           </button>
//         )}
//       </div>
//       {isOptionsOpen && !isEditing && (
//           <div className="options-window">
//             <button className="edit-button-ba" onClick={() => handleEditPost(post.id)}>Uprav</button>
//             <button className="delete-button" onClick={() => handleDeletePost(post.id)}>Zmaz</button>
//           </div>
//         )}
        
//       <div className="center-section">
//         {creatorName && <p className="creator-name">{creatorName}</p>}
//       </div>
//       <div className="right-section">
//         <button className="close-button" onClick={closePostDetail}>×</button>
//       </div>
//     </div>
    
//     <div className="post-header">
//       {isEditing ? (
//         <input 
//           type="text" 
//           value={editedTitle} 
//           onChange={(e) => setEditedTitle(e.target.value)} 
//           style={editedTitle ? {} : { border: '1px solid red' }} // Apply the style directly
//         />
//       ) : (
//         <h2>{post.title}</h2>
//       )}
//     </div>
      
//       <div className="post-detail-header">
//         {post.timeStamp && <p className="timestamp">{formatDate(post.timeStamp)}</p>}

//         {post.tags && post.tags.length > 0 && (
//           <div className="tags-container">
//             {post.tags.filter(tag => tag).map((tag, index) => (
//               <p key={index} className="tags-in-post">{tag}</p>
//             ))}
//           </div>
//         )}
        
//       </div>

//       {finalImage && (
//         <div className="post-image">
//           <img src={finalImage} alt={post.title} />
//         </div>
//       )}

//       <div className="post-content">
//         {isEditing ? (
//           <textarea 
//             value={editedContent} 
//             onChange={(e) => setEditedContent(e.target.value)} 
//             style={editedContent ? {} : { border: '1px solid red' }} // Apply the style directly
//           />
//         ) : (
//           post.content && <p dangerouslySetInnerHTML={{ __html: post.content }} />
//         )}
//                 {/* {creatorName && <p className="creator-name">Autor: {creatorName}</p>} Display creator's name */}

//       </div>
//       {isEditing && (
//           <button 
//             className={`save-button ${!editedTitle || !editedContent ? 'disabled-button' : ''}`} // Apply the 'disabled-button' class if the button is disabled
//             onClick={() => handleSavePost(post.id)}
//             disabled={!editedTitle || !editedContent}
//             style={!editedTitle || !editedContent ? { backgroundColor: 'grey', color: 'white' } : {}} // Apply the style directly
//           >
//             Save
//           </button>
//         )}
//     </div>
//   );
// };

// export default PostDetail;
import React, { useState, useEffect } from 'react';
import './PostDetail.css';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

const PostDetail = ({ post, closePostDetail, toggleEdit }) => {
  const [finalImage, setFinalImage] = useState('');

  useEffect(() => {
    // Fetch the image URL from Firebase storage
    const storage = getStorage();
    const storageRef = ref(storage, post.imageURL);

    getDownloadURL(storageRef)
      .then((url) => setFinalImage(url))
      .catch((error) => console.error('Error fetching image:', error));
  }, [post.imageURL]);

  return (
    <div className="post-detail-overlay">
      <div className="post-detail-container">
        {/* Image wrapper */}
        <div className="post-image-wrapper">
          {finalImage && <img src={finalImage} alt={post.title} />}
        </div>

        {/* Text content */}
        <div className="post-detail-content">
          <h2>{post.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: post.content }} />
        </div>

        {/* Bottom bar inside the container */}
        <div className="post-detail-bottom-bar">
          <button onClick={closePostDetail} aria-label="Go back">
            &#8592; {/* Back arrow */}
          </button>
          <button className="edit-button" onClick={toggleEdit}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostDetail;