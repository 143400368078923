import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import backgroundImage from './img/postb.jpg';
import './style.css';
import Post from './post/Post';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons'; // Import filter icon
import tags from './tags.json'; // Import tags from tags.json

import PostDetail from './PostDetail'; // Import the PostDetail component

const RenderPost = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPost, setSelectedPost] = useState(null);
  const [postLimit, setPostLimit] = useState(9);
  const [hasMorePosts, setHasMorePosts] = useState(true);
  const [selectedTag, setSelectedTag] = useState(''); // State for selected tag
  const [isFilterOpen, setIsFilterOpen] = useState(false); // State for filter window
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State for dropdown

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const snapshot = await firebase
          .firestore()
          .collection('Posts')
          .orderBy('timeStamp', 'desc')
          .limit(postLimit)
          .get();

        const postList = snapshot.docs.map((doc) => ({
          id: doc.id, // Include the document ID as the id property
          ...doc.data(),
        }));
        
        if (postList.length < postLimit) {
          // If the fetched posts are less than the limit, there are no more posts to load
          setHasMorePosts(false);
        }

        setPosts(postList);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching posts:', error);
        setLoading(false);
      }
    };

    fetchPosts();
  }, [postLimit]);

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const closePostDetail = () => {
    setSelectedPost(null);
  };

  const loadMorePosts = () => {
    if (hasMorePosts) {
      setPostLimit((prevLimit) => prevLimit + 9);
    }
  };

  const updatePost = (updatedPost) => {
    setSelectedPost(updatedPost);
    console.log('Updating post in parent component...');
  };

  const handleSearch = async (e) => {
    e.preventDefault(); // Prevent page refresh
    if (selectedTag.trim() === '') return;

    setLoading(true);
    try {
      const postsCollection = await firebase.firestore().collection('Posts')
        .where('tags', 'array-contains', selectedTag)
        .orderBy('timeStamp', 'desc')
        .limit(postLimit)
        .get();

      const postList = postsCollection.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPosts(postList);
    } catch (error) {
      console.error('Error searching posts:', error);
    } finally {
      setLoading(false);
      setIsFilterOpen(false); // Close filter window after search
    }
  };

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="whole-box">
            {posts.map((post, index) => (
              <div key={index} onClick={() => handlePostClick(post)} className="post-container-limit">
                <Post
                  title={post.title}
                  content={post.content}
                  bgColor={['#e0d6c2', '#8f8868', '#976c54', '#b5a38c'][index % 4]}
                  image={post.imageURL}
                />
              </div>
            ))}
          </div>
          {hasMorePosts && (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
              <button className="load-more-button" onClick={loadMorePosts}>
                Load More
              </button>
            </div>
          )}
          <img src={backgroundImage} alt="Background" className="background-image" />
        </>
      )}

      {/* {selectedPost && (
        <div className="post-detail-overlay">
          <div className="post-detail-container" style={{ background: '#e0d6c2' }}>
            <PostDetail post={selectedPost} updatePost={updatePost} closePostDetail={closePostDetail} />
          </div>
        </div>
      )} */}
{selectedPost && (
  <PostDetail
    post={selectedPost}
    updatePost={updatePost}
    closePostDetail={closePostDetail}
  />
)}
      <div className={`filter-container ${isFilterOpen ? 'open' : ''}`}>
        <button onClick={() => setIsFilterOpen(!isFilterOpen)} className="filter-button">
          <FontAwesomeIcon icon={faFilter} />
        </button>
        <div className="filter-window">
          <div className="custom-dropdown">
            <label className="dropdown-label" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
              {selectedTag || 'Select a tag'}
            </label>
            {isDropdownOpen && (
              <div className="section-dropdown">
                {tags.map((tag, index) => (
                  <a key={index} onClick={() => { setSelectedTag(tag); setIsDropdownOpen(false); }}>{tag}</a>
                ))}
              </div>
            )}
          </div>
          <button onClick={handleSearch} className="search-button">
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default RenderPost;